





















































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import serviceCategoryStoreModule from "@/store/modules/serviceCategory";
import SerivceList from "@/components/service/SerivceList.vue";
import ActionButtons from "@/components/ActionButtons.vue";
import { Business, Role } from "@/types";
import ServiceCategoryList from "@/components/service/ServiceCategoryList.vue";
import OrderList from "@/components/inventory/OrderList.vue";
// import PackagesList from "@/components/inventory/PackagesList.vue";
import PackagesList from "@/components/service/PackagesList.vue";
import AppointmentList from "@/components/AppointmentList.vue";
import ServiceDepositForm from "@/components/ServiceDepositForm.vue";
import ClientServicePackagesList from "@/components/client/ClientPackagesList.vue";

const {
  mapActions: serviceCategoryActions,
  mapGetters: serviceCategoryGetters,
} = createNamespacedHelpers("SERVICE_CATEGORIES");

export default Vue.extend<any, any, any, any>({
  components: {
    SerivceList,
    ActionButtons,
    ServiceCategoryList,
    OrderList,
    PackagesList,
    AppointmentList,
    ServiceDepositForm,
    ClientServicePackagesList,
  },
  name: "Services",
  data: () => ({
    loadingcategories: false,
    exporting: false,
    depositModal: false,
  }),
  watch: {
    role: "fetchCategories",
  },
  created() {
    this.loadingcategories = true;
    this.fetchCategories();
  },
  computed: {
    ...serviceCategoryGetters(["serviceCategoryPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...serviceCategoryActions(["fetchServiceCategoryList", "exportServices"]),
    fetchCategories() {
      if (this.role) {
        const params = `?businessId=${
          (this.role.business as Business)?._id
        }&limit=50`;
        this.fetchServiceCategoryList(params).then(() => {
          this.loadingcategories = false;
        });
      }
    },
    _export() {
      const params = `?businessId=${(this.role.business as Business)?._id}`;
      this.exporting = true;
      this.exportServices(params).then((res) => {
        this.exporting = false;
        if (res.data) {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "services.csv");
          document.body.appendChild(fileLink);

          fileLink.click();
        }
      });
    },
  },
  beforeCreate() {
    const namespace = `SERVICE_CATEGORIES`;
    if (!this.$store.hasModule(namespace)) {
      this.$store.registerModule(namespace, serviceCategoryStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SERVICE_CATEGORIES");
  },
});
